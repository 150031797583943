import { cn } from '@cardo/lib';
import { useEffect } from 'react';
import { IoClose } from 'react-icons/io5';

type DrawerProps = {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
  position?: 'left' | 'right' | 'top' | 'bottom';
  className?: string;
};

export function Drawer({
  open,
  onClose,
  children,
  position = 'top',
  className,
}: DrawerProps) {
  useEffect(() => {
    if (open) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [open]);
  return (
    <>
      {/* Drawer */}
      <div
        className={cn(
          'fixed z-50 bg-white shadow-lg transition-transform duration-300 ease-in-out',
          position === 'right' &&
            `top-0 right-0 h-full w-auto transform ${
              open ? 'translate-x-0' : 'translate-x-full'
            }`,
          position === 'left' &&
            `top-0 left-0 h-full w-auto transform ${
              open ? 'translate-x-0' : '-translate-x-full'
            }`,
          position === 'top' &&
            `top-0 left-0 w-full h-auto transform ${
              open ? 'translate-y-0' : '-translate-y-full'
            }`,
          position === 'bottom' &&
            `bottom-0 left-0 w-full h-auto transform ${
              open ? 'translate-y-0' : 'translate-y-full'
            }`,
          className,
          open ? 'opacity-1' : 'opacity-0',
        )}
      >
        {/* Close Button */}
        <button
          onClick={onClose}
          className="absolute top-4 right-4 z-[50] text-2xl"
        >
          <IoClose />
        </button>

        {/* Content */}
        <div className="p-6 h-full">{children}</div>
      </div>

      {/* Overlay */}
      {open && (
        <div
          className="fixed inset-0 bg-black bg-opacity-30"
          onClick={onClose}
        />
      )}
    </>
  );
}
