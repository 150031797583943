import type { LoaderFunctionArgs } from '@remix-run/node';
import { json } from '@remix-run/node';
import type { MetaFunction } from '@remix-run/react';
import { useLoaderData } from '@remix-run/react';
import {
  SearchBox,
  Hits,
  Index,
  Configure,
  getServerState,
  InstantSearchSSRProvider,
  InstantSearch,
} from 'react-instantsearch';
import Layout from '~/components/layout/Layout';
import { searchClient, searchIndexes } from '~/lib/search';
import { renderToString } from 'react-dom/server';
import { IoArrowForwardSharp, IoFilterSharp, IoSearch } from 'react-icons/io5';
import { generateAppUrl } from '~/lib/utils';
import CreditCardHit from '~/components/search/CreditCardHit';
import GenericHit from '~/components/search/GenericHit';
import { generateOgUrlMetaDescriptor } from '~/lib/meta';

import { history } from 'instantsearch.js/es/lib/routers';

export const meta: MetaFunction = ({ location }) => {
  return [
    { title: 'Cardonomics - Search' },
    {
      name: 'description',
      content:
        'Search for top credit cards and credit card calculators on Cardonomics.com',
    },
    generateOgUrlMetaDescriptor(location),
    {
      tagName: 'link',
      rel: 'canonical',
      href: generateAppUrl(location.pathname),
    },
  ];
};

export async function loader({ request }: LoaderFunctionArgs) {
  const serverUrl = new URL(request.url);
  const serverState = await getServerState(
    <Search serverUrl={serverUrl} />,

    { renderToString },
  );

  return json({
    serverState,
    serverUrl,
  });
}
export type SearchUiState = {
  [key: string]: {
    query?: string;
  };
};

export type SearchRouteState = {
  q?: string;
};

export const HITS_PER_PAGE = {
  creditCards: 6,
  blogs: 9,
  other: 3,
};
export const EXCLUDED_INDEXES = ['creditCards', 'blogs'];

function Search({ serverUrl }: { serverUrl: URL }) {
  if (!searchClient) return null;
  return (
    <div className="max-w-screen flex min-h-[800px] flex-col gap-6">
      <h1>Search</h1>
      <InstantSearch<SearchUiState, SearchRouteState>
        searchClient={searchClient}
        indexName={searchIndexes.creditCards.indexName}
        onStateChange={({ uiState, setUiState }) => {
          setUiState(uiState);
        }}
        routing={{
          router: history({
            getLocation() {
              if (typeof window === 'undefined') {
                return new URL(serverUrl!) as unknown as Location;
              }
              return window.location;
            },
            cleanUrlOnDispose: false,
          }),
          stateMapping: {
            stateToRoute(uiState) {
              const indexUiState =
                uiState[searchIndexes.creditCards.indexName] || {};
              return {
                q: indexUiState.query?.replace(/ /g, '+'),
              };
            },
            routeToState(routeState) {
              return Object.entries(searchIndexes).reduce<SearchUiState>(
                (acc, [_, index]) => {
                  acc[index.indexName] = {
                    ...acc[index.indexName],
                    query: routeState.q?.replace(/\+/g, ' '),
                  };
                  return acc;
                },
                {},
              );
            },
          },
        }}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        insights
      >
        <div className="flex flex-col gap-6">
          <SearchBox
            submitIconComponent={() => <IoSearch />}
            classNames={{
              input:
                'appearance-none w-full pl-8 rounded-md border border-gray-300 py-2 px-3 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
              submit: 'absolute left-2.5 flex h-full items-center',
              form: 'relative flex w-full',
              reset: 'hidden',
              loadingIndicator: 'absolute h-full right-10 top-0 bottom-0',
              loadingIcon: 'my-auto h-full',
            }}
            autoFocus={true}
          />
          <div className="flex flex-col gap-6 border-t pt-6">
            <Index indexName={searchIndexes.creditCards.indexName}>
              <Configure hitsPerPage={HITS_PER_PAGE.creditCards} />
              <Hits
                hitComponent={CreditCardHit}
                classNames={{
                  list: 'grid grid-cols-1 sm:grid-cols-3 gap-3',
                  emptyRoot: 'hidden',
                }}
              />
              <a
                href="/credit-cards/explore"
                className="flex items-center gap-2 text-theme-blue-darkest no-underline group pb-4 border-b"
              >
                <IoFilterSharp className="w-5 h-5 flex-shrink-0" />
                <span className="font-bold">
                  Refine your credit card search
                </span>
                <span className="text-slate-500">
                  Compare with detailed filters
                </span>
                <IoArrowForwardSharp className="w-5 h-5 flex-shrink-0 group-hover:translate-x-2 transition-transform" />
              </a>
            </Index>
            <div className="grid grid-cols-12 gap-6">
              <div className="col-span-12 sm:col-span-8">
                <Index indexName={searchIndexes.blogs.indexName}>
                  <Configure hitsPerPage={HITS_PER_PAGE.blogs} />
                  <Hits
                    classNames={{
                      list: 'flex flex-col gap-3',
                      root: 'mb-3',
                      emptyRoot: 'mb-0 hidden',
                    }}
                    hitComponent={({ hit }) => (
                      <GenericHit
                        hit={hit}
                        highlightAttributeName={
                          searchIndexes.blogs.highlightAttributeName
                        }
                        type={searchIndexes.blogs.type}
                        indexKey="blogs"
                      />
                    )}
                  />
                </Index>
              </div>
              <div className="col-span-12 sm:col-span-4">
                {Object.entries(searchIndexes)
                  .filter(([key]) => !EXCLUDED_INDEXES.includes(key))
                  .map(([indexKey, index]) => (
                    <Index key={indexKey} indexName={index.indexName}>
                      <Configure hitsPerPage={HITS_PER_PAGE.other} />
                      <Hits
                        classNames={{
                          list: 'flex flex-col gap-3',
                          root: 'mb-3',
                          emptyRoot: 'mb-0 hidden',
                        }}
                        hitComponent={({ hit }) => (
                          <GenericHit
                            hit={hit}
                            highlightAttributeName={
                              index.highlightAttributeName
                            }
                            type={index.type}
                            indexKey={
                              indexKey as
                                | 'tags'
                                | 'creditCardCategories'
                                | 'creditCardIssuers'
                            }
                          />
                        )}
                      />
                    </Index>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </InstantSearch>
    </div>
  );
}

export default function SearchPage() {
  const { serverState, serverUrl } = useLoaderData<typeof loader>();

  return (
    <Layout heroContainerClassName="h-8 sm:h-16">
      <InstantSearchSSRProvider {...serverState}>
        <Search serverUrl={new URL(serverUrl)} />
      </InstantSearchSSRProvider>
    </Layout>
  );
}
