import FooterNav, { FooterNavProps } from './FooterNav';
import { LayoutPadding } from './Layout';
import DOMPurify from 'isomorphic-dompurify';
import { useFooterContent } from '~/lib/utils';

export default function MainFooter(props: FooterNavProps) {
  const footer = useFooterContent();

  return (
    <LayoutPadding className="bg-white">
      <div className="h-10 border-t border-indigo-700"></div>
      <footer className="flex flex-col space-y-8 text-xs text-gray-500">
        <div className="flex flex-col gap-10 justify-between">
          <FooterNav {...props} />
        </div>
        {footer?.data?.attributes?.content && (
          <div
            className="prose-sm max-w-none text-xs"
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(footer.data.attributes.content),
            }}
          ></div>
        )}
      </footer>
    </LayoutPadding>
  );
}
