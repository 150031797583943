import { Link, useFetcher } from '@remix-run/react';
import type { Hit } from 'instantsearch.js';
import { useEffect } from 'react';
import { Highlight } from 'react-instantsearch';
import type { CreditCard } from '@cardo/types';
import { searchIndexes } from '~/lib/search';

const getLink = searchIndexes.creditCards.getLink;

export default function CreditCardHit({ hit }: { hit: Hit }) {
  const cardFetcher = useFetcher<{ creditCard: CreditCard }>();
  const card = cardFetcher.data?.creditCard;
  const cardArtUrl = card && card.attributes.cardArtUrl;

  const loading = cardFetcher.state === 'loading';

  useEffect(() => {
    if (cardFetcher.state === 'idle' && !cardFetcher.data && hit.id) {
      cardFetcher.load(`/resource/credit-cards/${hit.id}`);
    }
  }, [cardFetcher, hit.id]);

  return (
    <Link to={getLink(hit)} className="flex gap-2 no-underline hover:underline">
      <figure className="flex h-24 w-36 flex-shrink-0 items-start">
        {loading && (
          <div className="h-full w-full animate-pulse rounded-lg bg-gray-200" />
        )}
        {card && cardArtUrl && (
          <img
            src={cardArtUrl}
            alt={`card art for the ${card.attributes.cardName}`}
            className="mx-auto w-full rounded-lg object-contain"
          />
        )}
      </figure>

      <h4>
        <Highlight
          classNames={{
            highlighted:
              'underline decoration-theme-yellow decoration-4 bg-transparent',
          }}
          hit={hit}
          attribute="cardName"
        />
      </h4>
    </Link>
  );
}
