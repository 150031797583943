import { Link } from '@remix-run/react';
import NavGroup from './NavGroup';
import logo from '../../../images/logo-wide.svg';
import { useFooterContent } from '~/lib/utils';
import { SocialLink } from '@cardo/ui';
import { cn } from '@cardo/lib';

export type FooterNavProps = {
  logoTo?: string | null;
};

export default function FooterNav({ logoTo = '/' }: FooterNavProps) {
  const footer = useFooterContent();

  const authenticatedAppUrl =
    typeof document !== 'undefined'
      ? window.ENV.AUTHENTICATED_APP_URL
      : process.env.AUTHENTICATED_APP_URL;
  const linkStyle = 'no-underline text-black text-sm hover:underline';
  const titleStyle = 'font-bold border-b-0';
  const footerSocialLinks = (className: string) => (
    <div className={cn('space-x-1', className)}>
      {footer?.data?.attributes?.socialLinks?.socialLinks?.map((socialLink) => (
        <SocialLink key={socialLink.id} {...socialLink} />
      ))}
    </div>
  );

  const Logo = (
    <img src={logo} alt="cardonomics logo" width={180} height={30} />
  );

  return (
    <div className="flex flex-col md:flex-row justify-between gap-8 w-full">
      <div className="flex flex-col gap-8">
        {logoTo ? (
          <Link to={logoTo} className="flex items-center">
            {Logo}
          </Link>
        ) : (
          Logo
        )}
        {footerSocialLinks('hidden md:flex')}
      </div>

      <div className="flex flex-col md:flex-row  gap-6 lg:gap-10">
        <NavGroup title="Creators" titleClassName={titleStyle}>
          <Link className={linkStyle} to={authenticatedAppUrl || ''}>
            Login
          </Link>
          <Link className={linkStyle} to="/affiliates">
            Cardonomics Affiliate Program
          </Link>
        </NavGroup>
        <NavGroup title="Site" titleClassName={titleStyle}>
          <Link className={linkStyle} to="/terms-of-service">
            Terms of Service
          </Link>
          <Link className={linkStyle} to="/privacy-policy">
            Privacy Policy
          </Link>
          <Link className={linkStyle} to="/cookie-policy">
            Cookie Policy
          </Link>
        </NavGroup>
      </div>
      {footerSocialLinks('flex md:hidden')}
    </div>
  );
}
