import { useEffect, useRef, useState } from 'react';
import { IoSearch } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from '@remix-run/react';
import {
  Configure,
  Hits,
  Index,
  InstantSearch,
  SearchBox,
  useInstantSearch,
} from 'react-instantsearch';
import { cn } from '@cardo/lib';
import { Drawer } from '@cardo/ui';
import { searchClient, searchIndexes } from '~/lib/search';
import { useMegaMenuContent } from '~/lib/utils';
import GenericHit from './GenericHit';
import CreditCardHit from './CreditCardHit';
import { MenuItemSubLinksComponent } from '@cardo/types';
import {
  EXCLUDED_INDEXES,
  HITS_PER_PAGE,
  SearchRouteState,
  SearchUiState,
} from '~/routes/search';

// Constants
const SEARCH_PATH = '/search';

const SearchResults = ({ open }: { open: boolean }) => {
  const { results } = useInstantSearch();
  const searchValue = results.query;

  if (!searchValue) return null;

  return (
    <div
      className={cn(
        'flex flex-col gap-3 overflow-y-auto pb-[100px] md:pb-0 max-h-[100%]  md:max-h-[400px] w-full md:w-1/2',
        !open && 'hidden',
      )}
    >
      <Index indexName={searchIndexes.creditCards.indexName}>
        <Configure hitsPerPage={HITS_PER_PAGE.creditCards} />
        <Hits
          hitComponent={CreditCardHit}
          classNames={{
            list: 'grid grid-cols-1 gap-3',
            emptyRoot: 'hidden',
          }}
        />
      </Index>

      <Index indexName={searchIndexes.blogs.indexName}>
        <Configure hitsPerPage={HITS_PER_PAGE.blogs} />
        <Hits
          classNames={{
            list: 'flex flex-col gap-3',
            root: 'mb-3',
            emptyRoot: 'mb-0 hidden',
          }}
          hitComponent={({ hit }) => (
            <GenericHit
              hit={hit}
              highlightAttributeName={
                searchIndexes.blogs.highlightAttributeName
              }
              type={searchIndexes.blogs.type}
              indexKey="blogs"
            />
          )}
        />
      </Index>

      {Object.entries(searchIndexes)
        .filter(([key]) => !EXCLUDED_INDEXES.includes(key))
        .map(([indexKey, index]) => (
          <Index key={indexKey} indexName={index.indexName}>
            <Configure hitsPerPage={HITS_PER_PAGE.other} />
            <Hits
              classNames={{
                list: 'flex flex-col gap-3',
                root: 'mb-3',
                emptyRoot: 'mb-0 hidden',
              }}
              hitComponent={({ hit }) => (
                <GenericHit
                  hit={hit}
                  highlightAttributeName={index.highlightAttributeName}
                  type={index.type}
                  indexKey={
                    indexKey as
                      | 'tags'
                      | 'creditCardCategories'
                      | 'creditCardIssuers'
                  }
                />
              )}
            />
          </Index>
        ))}
    </div>
  );
};

const PopularTopics = ({ links }: { links: MenuItemSubLinksComponent[] }) => {
  const { results } = useInstantSearch();
  const searchValue = results.query;

  return (
    <div className={cn('pb-2 pt-4', searchValue && 'hidden md:block')}>
      <h2 className="text-lg font-semibold mb-3">Popular Topics</h2>
      <div className="grid grid-cols-1 gap-2">
        {links?.map((link, index) => (
          <Link
            key={index}
            to={link.to}
            className="text-sm text-gray-600 hover:text-blue-600 cursor-pointer capitalize no-underline"
          >
            {link.label}
          </Link>
        ))}
      </div>
    </div>
  );
};

const Search = ({
  open,
  closeModal,
  searchInputRef,
}: {
  open: boolean;
  closeModal: () => void;
  searchInputRef: React.RefObject<HTMLDivElement>;
}) => {
  const megaMenu = useMegaMenuContent();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSearchSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();

    const input = searchInputRef.current?.querySelector(
      'input',
    ) as HTMLInputElement;
    if (!input?.value) return;

    const searchQuery = encodeURIComponent(input.value);
    const searchUrl = `${SEARCH_PATH}?q=${searchQuery}`;

    if (location.pathname === SEARCH_PATH) {
      window.location.href = searchUrl;
    } else {
      navigate(searchUrl);
    }

    closeModal();
  };

  if (!searchClient) return null;

  return (
    <div className={'flex h-full flex-col gap-6 '}>
      <h1>Search</h1>
      <InstantSearch<SearchUiState, SearchRouteState>
        searchClient={searchClient}
        indexName={searchIndexes.creditCards.indexName}
        onStateChange={({ uiState, setUiState }) => {
          setUiState(uiState);
        }}
        future={{
          preserveSharedStateOnUnmount: true,
        }}
        insights
      >
        <div className="flex flex-col md:flex-row gap-6 h-full">
          <div className="flex flex-col w-full md:w-1/2">
            <div ref={searchInputRef}>
              <SearchBox
                submitIconComponent={() => <IoSearch />}
                classNames={{
                  input:
                    'appearance-none w-full pl-8 rounded-md border border-gray-300 py-2 px-3 text-base focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm',
                  submit: 'absolute left-2.5 flex h-full items-center',
                  form: 'relative flex w-full',
                  reset: 'hidden',
                  loadingIndicator: 'absolute h-full right-10 top-0 bottom-0',
                  loadingIcon: 'my-auto h-full',
                }}
                autoFocus={false}
                onSubmit={handleSearchSubmit}
              />
            </div>

            <PopularTopics
              links={megaMenu?.data.attributes.popularLinks ?? []}
            />
          </div>

          <SearchResults open={open} />
        </div>
      </InstantSearch>
    </div>
  );
};

const SearchDropDown = ({ className }: { className?: string }) => {
  const [open, setOpen] = useState(false);
  const searchInputRef = useRef<HTMLDivElement>(null);

  const handleClose = () => setOpen(false);

  useEffect(() => {
    if (!open) return;

    const timer = setTimeout(() => {
      const input = searchInputRef.current?.querySelector(
        'input',
      ) as HTMLInputElement;
      input?.focus();
    }, 50);

    return () => clearTimeout(timer);
  }, [open]);

  return (
    <>
      <IoSearch
        onClick={() => setOpen(true)}
        className={cn(
          'text-2xl lg:text-xl xl:text-2xl text-black cursor-pointer',
          className,
        )}
      />

      <Drawer
        open={open}
        onClose={handleClose}
        position="top"
        className={'h-screen md:h-fit min-h-[500px]'}
      >
        <div className=" px-0 md:px-14 flex flex-col gap-6 h-full">
          <Search
            open={open}
            closeModal={handleClose}
            searchInputRef={searchInputRef}
          />
        </div>
      </Drawer>
    </>
  );
};

export default SearchDropDown;
