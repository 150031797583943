import { Link } from '@remix-run/react';
import type { Hit } from 'instantsearch.js';
import { Highlight } from 'react-instantsearch';
import type { SearchIndexes } from '~/lib/search';
import { searchIndexes } from '~/lib/search';
import { Badge } from '@cardo/ui';

export default function GenericHit({
  hit,
  highlightAttributeName,
  type,
  indexKey,
}: {
  hit: Hit;
  highlightAttributeName: string;
  type: string;
  indexKey: SearchIndexes;
}) {
  const getLink = searchIndexes[indexKey].getLink;
  const link = getLink(hit);

  const getSubText = searchIndexes[indexKey].getSubText;

  return (
    <Link
      to={link}
      className="flex flex-col gap-3 rounded-md border p-3 no-underline hover:shadow-md"
    >
      <div className="flex flex-col gap-1.5">
        <h4>
          <Highlight
            classNames={{
              highlighted:
                'underline decoration-theme-yellow decoration-4 bg-transparent',
            }}
            hit={hit}
            attribute={highlightAttributeName}
          />
        </h4>
        {getSubText && typeof getSubText === 'function' && (
          <p className="text-theme-blue-darkest text-sm">{getSubText(hit)}</p>
        )}
      </div>
      <Badge color="transparent">{type}</Badge>
    </Link>
  );
}
